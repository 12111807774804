const RANKS = [
    {
        rank: -20,
        label: "Lowest"
    },
    {
        rank: -10,
        label: "Lower"
    },
    {
        rank: 0,
        label: "Medium"
    },
    {
        rank: 10,
        label: "Higher"
    },
    {
        rank: 20,
        label: "Highest"
    }
];

const ORIENTATIONS = [
    {
        orientation: 1,
        label: 'Portrait'
    },
    {
        orientation: 2,
        label: 'Landscape'
    }
];

export default {
    listRankOptions() {
        return RANKS
    },

    listMediaOrientations() {
        return ORIENTATIONS
    }
}