<template>
  <div>
    <!-- Team -->
    <div class="team" v-for="(skillId, i) in customRoute" :key="i">
      <div class="left">
        <a-form-item label="Next Response Team">
          <a-select
            :disabled="i + 1 < numberOfTeams"
            v-model="customRoute[i]"
            size="large"
            style="width: 100%"
          >
            <a-select-option :value="null" disabled
              >Please select a response team</a-select-option
            >
            <a-select-option
              v-for="skill in skillsToShowForIndex(i)"
              :value="skill.id"
              :key="`skill-${skill.id}`"
            >
              {{ skill.displayName }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </div>
      <div class="right">
        <a-button
          v-if="i < numberOfTeams - 1"
          type="danger"
          disabled
          size="large"
          icon="delete"
        ></a-button>

        <a-popconfirm
          title="Are you sure?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="removeLast"
        >
          <a-button
            v-if="i == numberOfTeams - 1"
            type="danger"
            icon="delete"
            size="large"
          ></a-button>
        </a-popconfirm>
      </div>
    </div>
    <!-- / Team -->

    <!-- Add Response Team -->
    <a-button
      icon="plus"
      @click.prevent="addResponseTeam"
      :disabled="!canAddResponseTeam"
      >Add Response Team</a-button
    >
    <!-- / Add Response Team -->
  </div>
</template>

<script>
const _ = require("lodash");
export default {
  props: ["initialRoute", "skills"],
  data() {
    return {
      customRoute: [],
    };
  },
  watch: {
    customRoute: {
      deep: true,
      handler() {
        this.$emit("set-custom-route", this.customRoute);
      },
    },
  },
  methods: {
    skillsToShowForIndex(i) {
      let skillsIdsToOmit = [];
      for (let j = 0; j < i; j++) {
        skillsIdsToOmit.push(this.customRoute[j]);
      }
      return _.filter(this.skills, (skill) => {
        return !_.includes(skillsIdsToOmit, skill.id);
      });
    },

    removeLast() {
      this.customRoute.pop();
    },

    addResponseTeam() {
      if (!this.canAddResponseTeam) {
        return false;
      }
      this.customRoute.push(null);
    },
  },
  computed: {
    numberOfTeams() {
      return this.customRoute.length;
    },

    canAddResponseTeam() {
      let lastTeam = _.last(this.customRoute);
      if (lastTeam !== null) {
        return true;
      }
      return false;
    },
  },
  created() {
    if (this.initialRoute && this.initialRoute.length) {
      this.customRoute = _.map(this.initialRoute, "skillId");
    }
  },
};
</script>

<style scoped lang="scss">
.team {
  display: flex;

  .left {
    flex-grow: 1;
  }

  .right {
    flex-shrink: 1;
    display: flex;
    align-items: center;
    padding-top: 14px;
    padding-left: 20px;
  }
}
</style>